<script setup>
import { onMounted } from "vue";
import PresentationComponent from "@/components/home/PresentationComponent.vue"
import FooterComponent from "@/components/home/FooterComponent.vue"

onMounted(() => {
  window.scrollTo({ top: 0, behavior: "smooth" });
});
</script>

<template>
  <PresentationComponent />
  <FooterComponent />
</template>