<script setup>
import { defineProps } from "vue";

const props = defineProps({
    buttonClass: {
        type: String,
    },
    componentClass: {
        type: String,
    },
    title: {
        type: String,
    },
    image: {
        type: Object,
    },
    customComponent: {
        type: Object,
    },
    isDisabled: {
        type: Boolean,
        default: false
    },
});
</script>

<template>
    <div :class="{'opacity-40': isDisabled}" class="group flex relative cursor-pointer">
        <a :class="props.buttonClass">
            <img :src="props.image" />
            {{ props.title }}
        </a>
        <component 
            class="hidden group-hover:block" 
            :class="props.componentClass"  
            :is="props.customComponent" 
        />
    </div>
</template>
