<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import useToggle from "../composable/useToggle.js";
import useToggleTheme from "../composable/useToggleTheme.js";

import logo from "../assets/logo.svg";

const { isDark, toggleTheme } = useToggleTheme();

const { isActive, toggleElement } = useToggle();

const menuElements = {
  logo: {
    img: logo,
    name: "SAS2D",
    version: "α 0.0.2",
  },
  themes: {
    background:
      "bg-gradient-to-r from-blue-800 to-violet-900 dark:from-gray-800 dark:to-gray-700",
    hover: "hover:bg-blue-700 hover:text-white",
    current:
      "bg-[#575efd] dark:bg-gradient-to-r dark:from-violet-700 dark:to-blue-600",
  },
  routes: [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "SAS2D",
      link: "sas2d",
    },
    {
      name: "Docs",
      link: "docs",
    },
    {
      name: "About",
      link: "/about",
    },
  ],
};

const store = useStore();
let isCurrent = computed(() => store.state.theme.currentTab);

function changeTab(newTab) {
  store.commit("changeTab", newTab);
}
</script>

<template>
  <nav
    :class="menuElements.themes.background"
    class="fixed top-0 w-full z-20 p-3 shadow-xl"
    id="navbar"
  >
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-8">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <button
            type="button"
            :class="menuElements.themes.hover"
            class="relative inline-flex items-center justify-center rounded-md p-2 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            aria-controls="mobile-menu"
            aria-expanded="false"
            @click="toggleElement"
          >
            <span class="absolute -inset-0.5"></span>
            <span class="sr-only">Open main menu</span>

            <!--
            Icon when menu is closed.
            Menu open: "hidden", Menu closed: "block"
          -->
            <svg
              class="block h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>

            <!--
            Icon when menu is open.
            Menu open: "block", Menu closed: "hidden"
          -->
            <svg
              class="hidden h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div
          class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start"
        >
          <div class="flex flex-shrink-0 items-center cursor-default">
            <img
              class="h-14 w-auto p-1"
              :src="menuElements.logo.img"
              alt="sas2d logo"
            />
            <h1 class="text-white ml-1 font-medium">
              {{ menuElements.logo.name }}
            </h1>
            <p class="text-gray-400 text-xs ml-1 pt-3">
              {{ menuElements.logo.version }}
            </p>
          </div>

          <!-- menu componentes -->
          <div class="hidden sm:ml-6 sm:flex items-center justify-center">
            <div class="flex space-x-4">
              <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
              <router-link
                v-for="route in menuElements.routes"
                :key="route.name"
                :to="route.link"
              >
                <a
                  @click="changeTab(route.name)"
                  :class="[
                    menuElements.themes.hover,
                    { [menuElements.themes.current]: isCurrent == route.name },
                  ]"
                  class="text-white hover:bg-blue-700 rounded-md px-3 py-2 text-sm font-medium"
                  aria-current="page"
                >
                  {{ route.name }}
                </a>
              </router-link>
            </div>
          </div>
        </div>

        <!--
          Icon to toggle theme.
        -->
        <button
          @click="toggleTheme"
          type="button"
          :class="[menuElements.themes.current, menuElements.themes.hover]"
          class="text-white absolute right-0 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-3 py-2 text-center me-2 mb-2 dark:focus:ring-blue-800"
        >
          <svg
            v-if="isDark"
            data-toggle-icon="sun"
            class="w-3.5 h-3.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              d="M10 15a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm0-11a1 1 0 0 0 1-1V1a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1Zm0 12a1 1 0 0 0-1 1v2a1 1 0 1 0 2 0v-2a1 1 0 0 0-1-1ZM4.343 5.757a1 1 0 0 0 1.414-1.414L4.343 2.929a1 1 0 0 0-1.414 1.414l1.414 1.414Zm11.314 8.486a1 1 0 0 0-1.414 1.414l1.414 1.414a1 1 0 0 0 1.414-1.414l-1.414-1.414ZM4 10a1 1 0 0 0-1-1H1a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1Zm15-1h-2a1 1 0 1 0 0 2h2a1 1 0 0 0 0-2ZM4.343 14.243l-1.414 1.414a1 1 0 1 0 1.414 1.414l1.414-1.414a1 1 0 0 0-1.414-1.414ZM14.95 6.05a1 1 0 0 0 .707-.293l1.414-1.414a1 1 0 1 0-1.414-1.414l-1.414 1.414a1 1 0 0 0 .707 1.707Z"
            ></path>
          </svg>
          <svg
            v-else
            data-toggle-icon="moon"
            class="w-3.5 h-3.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 18 20"
          >
            <path
              d="M17.8 13.75a1 1 0 0 0-.859-.5A7.488 7.488 0 0 1 10.52 2a1 1 0 0 0 0-.969A1.035 1.035 0 0 0 9.687.5h-.113a9.5 9.5 0 1 0 8.222 14.247 1 1 0 0 0 .004-.997Z"
            ></path>
          </svg>
        </button>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div
      :class="[menuElements.themes.background, { hidden: !isActive }]"
      class="absolute w-full -left-1/2 translate-x-1/2 top-14"
      id="mobile-menu"
    >
      <div class="space-y-1 px-2 pb-3 pt-2 text-center">
        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
        <router-link
          v-for="route in menuElements.routes"
          :key="route.name"
          :to="route.link"
        >
          <a
            @click="
              changeTab(route.name);
              toggleElement();
            "
            :class="{ [menuElements.themes.current]: isCurrent == route.name }"
            class="text-white block rounded-md px-3 py-2 text-base font-medium"
            aria-current="page"
          >
            {{ route.name }}
          </a>
        </router-link>
      </div>
    </div>
  </nav>
</template>
