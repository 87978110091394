<script setup>
	import { defineProps, computed } from 'vue';

	const props = defineProps({
		x1: {
			type: Number,
			required: true
		},
		y1: {
			type: Number,
			required: true
		},
		x2: {
			type: Number,
			required: true
		},
		y2: {
			type: Number,
			required: true
		},
		loadType: {
			type: Number,
		},
		loadValue: {
			type: Number
		}
	});

	const { point_1, point_2, point_min, point_max, point_med, theta } = useGetData();

	const drawLoadsLines = computed( () => useGetLoadLines() );

	function useGetData() {
		const point_1 = {
			x: props.x1, y: props.y1
		},
		point_2 = {
			x: props.x2, y: props.y2
		},
		point_min = {
			x: Math.min( props.x1, props.x2 ),
			y: Math.min( props.y1, props.y2 )
		},
		point_max = {
			x: Math.max( props.x1, props.x2 ),
			y: Math.max( props.y1, props.y2 )
		},
		point_med = {
			x: point_min.x + ( point_max.x - point_min.x ) / 2,
			y: point_min.y + ( point_max.y - point_min.y ) / 2
		},
		DeltaX = props.x2 - props.x1, DeltaY = props.y2 - props.y1,
		theta = 180 * Math.atan2(DeltaY, DeltaX) / Math.PI;

		return {
			point_1, point_2, point_min, point_max, point_med, theta
		}
	}

	function useGetLoadLines() {
		const loadLinesDict = {
			0: { flag: 0 },
			1: {
					headLine: {
						x1: point_min.x, 
						y1: point_max.y + 0.4, 
						x2: point_max.x, 
						y2: point_max.y + 0.4
					},
					arrow: {
						length: 0.3,
						angle: -90,
					},
					text: {
						x: point_med.x,
						y: point_max.y + 0.5,
						angle: 0,
						value: props.loadValue
					},
					flag: point_max.x - point_min.x
				},
			2: {
					headLine: {
						x1: point_min.x, 
						y1: point_max.y + 0.05, 
						x2: point_max.x, 
						y2: point_max.y + 0.05
					},
					arrow: {
						length: 0.3,
						angle: 90
					},
					text: {
						x: point_med.x,
						y: point_max.y + 0.45,
						angle: 0,
						value: props.loadValue
					},
					flag: point_max.x - point_min.x
				},
			3: {
					headLine: {
						x1: point_1.x, 
						y1: point_1.y + 0.45, 
						x2: point_2.x, 
						y2: point_2.y + 0.45
					},
					arrow: {
						length: 0.3,
						angle:  -theta - 90
					},
					text: {
						x: point_med.x,
						y: point_med.y + 0.50,
						angle: 0,
						value: props.loadValue
					},
					flag: point_max.x - point_min.x
				},
			4: {
					headLine: {
						x1: point_1.x, 
						y1: point_1.y + 0.05, 
						x2: point_2.x, 
						y2: point_2.y + 0.05
					},
					arrow: {
						length: 0.3,
						angle: - theta + 90
					},
					text: {
						x: point_med.x,
						y: point_med.y + 0.45,
						angle: 0,
						value: props.loadValue
					},
					flag: point_max.x - point_min.x
				},
			5: {
					headLine: {
						x1: point_min.x - 0.45, 
						y1: point_min.y, 
						x2: point_min.x - 0.45, 
						y2: point_max.y
					},
					arrow: {
						length: 0.3,
						angle: -90
					},
					text: {
						x: point_min.x - 0.55,
						y: point_med.y,
						angle: 90,
						value: props.loadValue
					},
					flag: point_2.y - point_1.y
				},
			6: {
					headLine: {
						x1: point_min.x - 0.05, 
						y1: point_min.y, 
						x2: point_min.x - 0.05, 
						y2: point_max.y
					},
					arrow: {
						length: 0.3,
						angle: 90
					},
					text: {
						x: point_min.x - 0.45,
						y: point_med.y,
						angle: 90,
						value: props.loadValue
					},
					flag: point_2.y - point_1.y
				},
			7: {
					headLine: {
						x1: point_1.x - 0.45, 
						y1: point_1.y, 
						x2: point_2.x - 0.45, 
						y2: point_2.y
					},
					arrow: {
						length: 0.3,
						angle: -theta
					},
					text: {
						x: point_med.x - 0.55,
						y: point_med.y,
						angle: 0,
						value: props.loadValue
					},
					flag: point_2.y - point_1.y
				},
			8: {
					headLine: {
						x1: point_1.x - 0.05, 
						y1: point_1.y, 
						x2: point_2.x - 0.05, 
						y2: point_2.y
					},
					arrow: {
						length: 0.3,
						angle: -theta + 180
					},
					text: {
						x: point_med.x - 0.45,
						y: point_med.y,
						angle: 0,
						value: props.loadValue
					},
					flag: point_2.y - point_1.y
				},
			9: {
					headLine: {
						x1: point_1.x + 0.4*Math.cos(( theta + 90 )*Math.PI/180), 
						y1: point_1.y + 0.4*Math.sin(( theta + 90 )*Math.PI/180), 
						x2: point_2.x + 0.4*Math.cos(( theta + 90 )*Math.PI/180), 
						y2: point_2.y + 0.4*Math.sin(( theta + 90 )*Math.PI/180)
					},
					arrow: {
						length: 0.3,
						angle: -90
					},
					text: {
						x: (point_1.x + point_2.x)/2 + 0.5*Math.cos(( theta + 90 )*Math.PI/180),
						y: (point_1.y + point_2.y)/2 + 0.5*Math.sin(( theta + 90 )*Math.PI/180),
						angle: 0,
						value: props.loadValue
					},
					flag: (point_2.x-point_1.x)**2 + (point_2.y-point_1.y)**2
				},
			10: {
					headLine: {
						x1: point_1.x + 0.05*Math.cos(( theta + 90 )*Math.PI/180), 
						y1: point_1.y + 0.05*Math.sin(( theta + 90 )*Math.PI/180), 
						x2: point_2.x + 0.05*Math.cos(( theta + 90 )*Math.PI/180), 
						y2: point_2.y + 0.05*Math.sin(( theta + 90 )*Math.PI/180)
					},
					arrow: {
						length: 0.3,
						angle: 90
					},
					text: {
						x: (point_1.x + point_2.x)/2 + 0.5*Math.cos(( theta + 90 )*Math.PI/180),
						y: (point_1.y + point_2.y)/2 + 0.5*Math.sin(( theta + 90 )*Math.PI/180),
						angle: 0,
						value: props.loadValue
					},
					flag: (point_2.x-point_1.x)**2 + (point_2.y-point_1.y)**2
				},
			11: props.loadValue >= 0? 
				{
					path: {
						d: 'M '+(point_1.x+0.1)+','+(point_1.y-0.2)+' A 0.2,0.2 0 0 1 '+(point_1.x+0.1)+','+(point_1.y+0.2),
						transform: 'rotate('+theta+','+point_1.x+', '+point_1.y+')'
					},
					text: {
						x: point_1.x + 0.35*Math.cos(( theta+90 )*Math.PI/180),
						y: point_1.y + 0.35*Math.sin(( theta+90 )*Math.PI/180),
						angle: 0,
						value: props.loadValue,
					}
				}: 
				{
					path: {
						d: 'M '+(point_1.x+0.3)+','+(point_1.y-0.2)+' A 0.2,0.2 0 1 0 '+(point_1.x+0.3)+','+(point_1.y+0.2),
						transform: 'rotate('+theta+','+point_1.x+', '+point_1.y+')'
					},
					text: {
						x: point_1.x + 0.25*Math.cos(( theta+90 )*Math.PI/180),
						y: point_1.y + 0.25*Math.sin(( theta+90 )*Math.PI/180),
						angle: 0,
						value: -props.loadValue,
					}
				},
			12: props.loadValue >= 0?
				{
					path: {
						d: 'M '+(point_2.x-0.05)+','+(point_2.y-0.2)+' A 0.2,0.2 0 1 0 '+(point_2.x-0.05)+','+(point_2.y+0.2),
						transform: 'rotate('+theta+','+point_2.x+', '+point_2.y+')'
					},
					text: {
						x: point_2.x + 0.35*Math.cos(( theta+90 )*Math.PI/180),
						y: point_2.y + 0.35*Math.sin(( theta+90 )*Math.PI/180),
						angle: 0,
						value: props.loadValue,
					}
				}:
				{
					path: {
						d: 'M '+(point_2.x-0.3)+','+(point_2.y-0.2)+' A 0.2,0.2 0 0 1 '+(point_2.x-0.3)+','+(point_2.y+0.2),
						transform: 'rotate('+theta+','+point_2.x+', '+point_2.y+')'
					},
					text: {
						x: point_2.x + 0.3*Math.cos(( theta+90 )*Math.PI/180),
						y: point_2.y + 0.3*Math.sin(( theta+90 )*Math.PI/180),
						angle: 0,
						value: -props.loadValue,
					}
				}
		}

		function distributedLoad({ headLine, arrow, text, flag }) {
			//Check if flag is not zero
			if ( flag === 0 ) { return { headLine: { x1:0, y1:0, x2:0, y2:0 }, text: {x:0, y:0, angle: 0}} }

			//dL - Distance between arrows
			//arrowLength - length of arrow
			//deltaX and deltaY - Length X and Y of the headline
			
			let deltaX = headLine.x2 - headLine.x1, deltaY =  headLine.y2 - headLine.y1,
			lineLength = (( deltaX**2 + deltaY**2 ) ** 0.5).toFixed(2), 
			dL = lineLength >= 0.2? 0.2 : lineLength,
			lineDict = {
				arrowAngle: arrow.angle,
				headLine: headLine,
				arrowLines: [],
				text: text
			},
			unitVector = { x: deltaX/lineLength, y: deltaY/lineLength },
			n = Math.max(Math.ceil( lineLength/dL ), 2), adjust = lineLength%dL/(n - 1);


			for (let i = 0; i < n ; i++) {
				let initialPoint = {
						x: headLine.x1 + i*(dL + adjust)*unitVector.x,
						y: headLine.y1 + i*(dL + adjust)*unitVector.y
				};
				lineDict['arrowLines'].push({
						x1: initialPoint.x,
						y1: initialPoint.y,
						x2: initialPoint.x + arrow.length*unitVector.x,
						y2: initialPoint.y + arrow.length*unitVector.y
				})
			}
			lineDict['arrowLines'].push({
				x1: headLine.x2,
				y1: headLine.y2,
				x2: headLine.x2 + arrow.length*unitVector.x,
				y2: headLine.y2 + arrow.length*unitVector.y
			})

			return lineDict;
		}

		return props.loadType === 11 || props.loadType === 12? loadLinesDict[ props.loadType ]: distributedLoad( loadLinesDict[ props.loadType ] );
	}

</script>

<template>
	<g v-if=" loadType === 11 || loadType === 12">
		<path 
			marker-end="url(#arrowheadred)"
			:d="drawLoadsLines.path.d"
			stroke-width="0.03"
			fill="none"
			stroke="red"
			:transform="drawLoadsLines.path.transform"
		></path>
		<text
			text-anchor="middle"
			:x="drawLoadsLines.text.x"
			:y="-drawLoadsLines.text.y"
			:transform="'rotate('+drawLoadsLines.text.angle+','+drawLoadsLines.text.x+','+drawLoadsLines.text.y+') scale(1,-1)'"
			font-size="0.2px" fill="red" filter="url(#textBackground)"
		>{{ drawLoadsLines.text.value }}</text>
	</g>
	<g v-else>
		<line 
			:x1="drawLoadsLines.headLine.x1" :y1="drawLoadsLines.headLine.y1" :x2="drawLoadsLines.headLine.x2" :y2="drawLoadsLines.headLine.y2" 
			stroke-width="0.03"
			stroke="red"
		></line>
		<line  v-for="loadArrowLines in drawLoadsLines.arrowLines" :key="loadArrowLines"
			:x1="loadArrowLines.x1" 
			:y1="loadArrowLines.y1" 
			:x2="loadArrowLines.x2" 
			:y2="loadArrowLines.y2" 
			stroke-width="0.02"
			stroke="red" 
			marker-end="url(#arrowheadred)" 
			:transform="'rotate('+drawLoadsLines.arrowAngle+','+loadArrowLines.x1+','+loadArrowLines.y1+')'"
		/>
		<text
			text-anchor="middle"
			:x="drawLoadsLines.text.x"
			:y="-drawLoadsLines.text.y"
			:transform="'rotate('+drawLoadsLines.text.angle+','+drawLoadsLines.text.x+','+drawLoadsLines.text.y+') scale(1,-1)'"
			font-size="0.2px" fill="red" filter="url(#textBackground)"
		>{{ drawLoadsLines.text.value }}</text>
	</g>
</template>