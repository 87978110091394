<script setup>
const elements = [
{
        title: "New",
    },
    {
        title: "Open",
    },
    {
        title: "Save as Json",
    },
    {
        title: "Save as pdf",
    }
];
</script>

<template>
    <ul>
        <li v-for="element in elements" :key="element.title" class="p-2 hover:bg-blue-600">
            {{ element.title }}
        </li>
    </ul>
</template>
