<script setup>
  import HomeView from "./HomeView.vue";
  import { onMounted } from 'vue';

  onMounted(() => {
    const navbarHeight = document.getElementById('navbar').offsetHeight; // Altura da barra de navegação
    const element = document.getElementById('about'); // Elemento de destino

    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.scrollY; // Posição do elemento na página
      const scrollTo = elementPosition - navbarHeight - 10; // Posição de rolagem considerando a altura da barra de navegação
      window.scrollTo({ top: scrollTo, behavior: 'smooth' }); // Rolar suavemente até o elemento
    }
  });
</script>

<template>
  <HomeView />
</template>