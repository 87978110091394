<script setup>
import NavComponent from "./components/NavComponent.vue";
import useToggleTheme from "./composable/useToggleTheme.js";

const { isDark } = useToggleTheme();
</script>

<template>
  <div :class="{ dark: isDark }" class="p-0 m-0 ">
    <section class="flex flex-col min-h-[100dvb] bg-gradient-to-r from-blue-800 to-violet-900 sm:min-h-screen dark:from-gray-800 dark:to-gray-700">
      <NavComponent />
      <transition name="fade">
        <router-view class="mt-14 p-0 overflow-hidden" />
      </transition>
    </section>
  </div>
</template>

<style>
* {
  scroll-snap-align: start;
  scroll-behavior: smooth;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.2s ease-out;
}


.fade-enter-from,
.fade-leave-to {
  transform: scale(0.95);
  opacity: 0.5;
}
</style>
