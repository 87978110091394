<script setup>

import checkBox from "./inputs/checkBox.vue";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();

const elements = [
	{
		label: "Display Grid",
		value: computed(() => store.state.svgConfig.grid.show ),
		update: () => { store.state.svgConfig.grid.show = !store.state.svgConfig.grid.show }
	}
]

</script>

<style scoped></style>

<template>
    <ul>
        <li v-for="element in elements" :key="element.label" class="p-2 hover:bg-blue-600">
            <checkBox
				:label="element.label"
                :value="element.value.value"
				@inputEvent="element.update"
                className="text-white"
            />
        </li>
    </ul>
</template>
