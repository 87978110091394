<script setup>
import useToggleTabs from "@/composable/useToggleTabs.js";

const { isActive, toggleTab } = useToggleTabs();
</script>

<style scoped>
.selected {
  position: relative; /* Importante para o posicionamento do pseudo-elemento */
}

/* marker */
.selected:after {
  content: "";
  position: absolute;
  overflow: hidden;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 16px solid #9ca3af;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  transition: opacity 1s ease-in-out;
}
</style>

<template>
  <div
    class="flex z-10 items-center justify-center bg-gradient-to-r from-blue-800 to-violet-900 p-2 h-24 dark:from-gray-800 dark:to-gray-700"
  >
    <div
      :class="{ selected: isActive[0] }"
      @click="toggleTab(0)"
      class="w-16 p-0 mx-3 items-center justify-center flex-col cursor-pointer"
    >
      <a class="text-white font-medium text-sm">Drawing</a>
      <img class="m-auto h-10 w-10" src="@/assets/buttons/drawing.svg" />
    </div>
    <div
      :class="{ selected: isActive[1] }"
      @click="toggleTab(1)"
      class="w-16 p-0 mx-3 items-center justify-center flex-col cursor-pointer"
    >
      <a class="text-white font-medium text-sm">Elements</a>
      <img class="m-auto h-10 w-10" src="@/assets/buttons/elements.svg" />
    </div>
    <div
      :class="{ selected: isActive[2] }"
      @click="toggleTab(2)"
      class="w-16 p-0 mx-3 items-center justify-center flex-col cursor-pointer"
    >
      <a class="text-white font-medium text-sm">Matrices</a>
      <img class="m-auto h-10 w-10" src="@/assets/buttons/matrices.svg" />
    </div>
    <div
      class="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-0 h-0 border-b-4 border-l-4 border-r-4 border-transparent border-blue-500"
    ></div>
  </div>
</template>
