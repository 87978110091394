import { ref } from 'vue';

const isActive = ref([ true, false, false ]);

export default function useToggleTabs() {

	function toggleTab(index) {
		isActive.value = [false, false, false];
		isActive.value[index] = true;
	}
	

	return {
		isActive,
		toggleTab
	};
}