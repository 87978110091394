<script setup>
import { defineEmits, defineProps } from "vue";

const props = defineProps({
    label: {
        type: String,
        required: true,
    },
    value: {
        type: Boolean,
        required: true,
    },
    className: {
        type: String,
    },
});

const emits = defineEmits(["inputEvent"]);

function updateValue(event) {
    emits("inputEvent", event.target.value);
}
</script>

<template>
    <div class="flex items-center ps-2" :class="className" @click.stop="updateValue">
        <input
            v-if="props.value"
            type="checkbox"
            class="w-4 h-4 text-blue-900 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 cursor-pointer"
        checked />
        <input
            v-else
            type="checkbox"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 cursor-pointer"
        />
        <label class="w-full py-1 cursor-pointer">{{ props.label }}</label>
    </div>
</template>
