<script setup>
import tabBar from "./bars/tabBar.vue";
import menuBar from "./bars/menuBar.vue";
import AlertHolder from "./AlertHolder.vue";
import SvgPanZoom from "./svg/SvgPanZoom.vue";
import StructureComponent from "./svg/StructureComponent.vue";
import useToggleTabs from "@/composable/useToggleTabs.js";

import { ref, onMounted } from "vue";
import { useStore } from "vuex";

let viewBox = ref("0 0 2 2");

const handleSvgViewBox = (vBox) => {
    viewBox.value = "0 0 " + vBox;
};

const { isActive } = useToggleTabs();

onMounted(() => {
    const store = useStore();
    store.commit("newAlert", {
        isYellow: true,
        title: "Tips",
        msg: `Start by clicking 'Add' and add a new Point`,
    });
});
</script>

<style scoped></style>

<template>
    <section class="flex flex-col flex-grow w-full h-full relative select-none">
        <tabBar />
        <section
            class="relative w-full h-full flex flex-col-reverse sm:flex-row bg-gray-400 flex-grow border-t-[0px] shadow-sm border-gray-300"
        >
            <menuBar class="z-10 w-full h-fit sm:h-full sm:w-fit" />
            <article
                v-if="isActive[0]"
                class="h-full w-full flex-1 relative items-center justify-center flex"
            >
                <SvgPanZoom
                    :vBox="viewBox"
                    class="align-middle mt-4 w-[80%] h-[80%] max-w-[700px] max-h-[700px]"
                >
                    <StructureComponent
                        @svg-viewbox="handleSvgViewBox"
                    ></StructureComponent>
                </SvgPanZoom>
            </article>
        </section>
        <AlertHolder />
    </section>
</template>
