<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "vuex";

import LoadBar from "./LoadBar.vue";

import useDrawClick from "@/composable/useDrawClick";

const props = defineProps({
   barIndex: Number,
});

const { isActive, focusElement } = useDrawClick(
   "bar-" + props.barIndex,
   false,
   props.barIndex
);

const { bar, point_1, point_2, barData } = useBarInfo();

function useBarInfo() {
   const store = useStore();

   const bar = computed(() => {
      return store.state.structure.barList[props.barIndex];
   });

   const point_1 = computed(() => {
      return store.state.structure.pointList[bar.value.point_1];
   });

   const point_2 = computed(() => {
      return store.state.structure.pointList[bar.value.point_2];
   });

   const barData = computed(() => {
      return store.getters.barData({ barIndex: props.barIndex });
   });

   return {
      bar,
      point_1,
      point_2,
      barData,
   };
}

let color = computed(() => {
   return isActive.value ? "red" : "black";
});
</script>

<template>
   <g @click="focusElement">
      <g
         :transform="
            'rotate(' + barData.theta + ',' + point_1.x + ',' + point_1.y + ')'
         "
      >
         <rect
            :x="point_1.x"
            :y="point_1.y - 0.25"
            :width="barData.length"
            height="0.5"
            opacity="0"
         ></rect>
         <line
            :x1="point_1.x"
            :y1="point_1.y"
            :x2="point_1.x + barData.length"
            :y2="point_1.y"
            stroke-width="0.03"
            :stroke="color"
         ></line>
         <circle
            v-if="bar.rot[0]"
            :cx="point_1.x + 0.09"
            :cy="point_1.y"
            r="0.04"
            :stroke="color"
            fill="white"
            stroke-width="0.02"
         ></circle>
         <circle
            v-if="bar.rot[1]"
            :cx="point_1.x + barData.length - 0.09"
            :cy="point_1.y"
            r="0.04"
            :stroke="color"
            fill="white"
            stroke-width="0.02"
         ></circle>
         <text
            class="textLoad"
            text-anchor="middle"
            :x="point_1.x + barData.length / 2"
            :y="-point_1.y + 0.1"
            font-size="0.2px"
            :fill="color"
            filter="url(#textBackground)"
         >
            {{ barIndex + 1 }}
         </text>
      </g>
      <LoadBar
         :x1="point_1.x"
         :y1="point_1.y"
         :x2="point_2.x"
         :y2="point_2.y"
         :loadType="bar.draw_loads[0]"
         :loadValue="bar.draw_loads[1]"
      ></LoadBar>
      <!-- <LoadBarComponent :barIndex="barIndex" /> -->
   </g>
</template>

<style scoped>
.textLoad {
   scale: 1 -1;
}
</style>
