<script setup>
import fileButton from "@/assets/buttons/fileButton.svg";
import displayButton from "@/assets/buttons/displayButton.svg";
import addButton from "@/assets/buttons/addButton.svg";
import editButton from "@/assets/buttons/editButton.svg";
import solveButton from "@/assets/buttons/solveButton.svg";

import fileForm from "./forms/fileForm.vue";
import displayForm from "./forms/displayForm.vue";
import addForm from "./forms/addForm.vue";
import editForm from "./forms/editForm.vue";
import solveForm from "./forms/solveForm.vue";

import buttonBar from "./buttonBar.vue";

const btnConfig = {
    size: 12,
    margin: 4,
    imgSize: 10,
};

const btns = [
    {
        label: "File",
        componentClass:
            "bg-gray-800 text-white w-40 h-fit absolute bottom-[5.3rem] shadow-xl sm:left-16 sm:top-1",
        img: fileButton,
        component: fileForm
    },
    {
        label: "Display",
        componentClass:
            "bg-gray-800 text-white w-40 h-fit absolute bottom-[5.3rem] shadow-xl sm:left-16 sm:top-1",
        img: displayButton,
        component: displayForm,
    },
    {
        label: "Add",
        img: addButton,
        component: addForm,
    },
    {
        label: "Edit",
        img: editButton,
        component: editForm,
        isDisabled: true
    },
    {
        label: "Solve",
        img: solveButton,
        component: solveForm,
        isDisabled: true
    },
];
</script>

<style scoped></style>

<template>
    <div
        class="justify-center w-full flex sm:justify-start sm:h-full"
        :class="`bottom-0 p-0 h-${btnConfig.size + 2 * btnConfig.margin} bg-gray-700`"
    >
        <div
            class="relative flex flex-row mx-auto my-0 sm:flex-col sm:w-full sm:mx-auto"
        >
            <buttonBar
                v-for="btn in btns"
                :key="btn.label"
                :title="btn.label"
                buttonClass="w-16 text-white m-0 p-2 hover:bg-blue-700"
                :componentClass="btn.componentClass"
                :image="btn.img"
                :customComponent="btn.component"
                :isDisabled="btn.isDisabled"
            />
        </div>
    </div>
</template>
