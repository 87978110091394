import { ref } from 'vue';

export default function useToggle() {
    
    let isActive = ref(false);

    function toggleElement() {
        isActive.value =!isActive.value;
    }

    return {
        isActive,
        toggleElement
    }
}