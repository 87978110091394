import { ref } from "vue";

let isDark = ref(false);

export default function useToggleTheme() {

    function toggleTheme() {
        isDark.value = !isDark.value;
    }

    return {
        isDark,
        toggleTheme
    }
}