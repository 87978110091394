import { ref } from 'vue';
import { useStore } from "vuex";

let previousClick = null;

export default function useDrawClick(elId, isPoint, index) {
	const store = useStore();
	let isActive = ref(false);

	function listener(event) {
		const element = document.getElementById(elId);
		//click outside element id
		if ( element && !element.contains(event.target) ) {
			unFocusElement()
		}
	}


	function focusElement() {
		//Unfocus and remove any previous event listener before setting a new one
		if ( previousClick instanceof Function) {
			previousClick();
		}
		store.state.svgConfig.edit.isEditing = true;
		store.state.svgConfig.edit.isPoint = isPoint;
      store.state.svgConfig.edit.index = index;
		isActive.value = true;
		//Save function to any other instance
		previousClick = unFocusElement;
		window.addEventListener("click", listener);
	}

	function unFocusElement() {
		store.state.svgConfig.edit.isEditing = false;
		isActive.value = false;
		window.removeEventListener("click", listener);
	}

	return {
		isActive,
		focusElement
	}
}